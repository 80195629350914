.card {
  margin-top: 30px;
}
.card a {
  text-decoration: none;
  color: black;
}
.card-icon {
  width: auto;
  font-size: 100px;
  color: #6a6add;
  padding: 20px;
}
