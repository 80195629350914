.quotation a{
    display: inline-block;
    margin: 10px 0px;
    padding: 20px 40px;
    border: 1px solid #F15A2B;
    background: #F15A2B;
    color: white;
    text-decoration: none;
    transition: .3s ease-out;
}
.quotation{
    margin: 40px 0px;
    padding: 90px;
    background: #F9957A;
    color: white;
}
.quotation h2{
    font-size: 45px;
}
.quotation h3{
    font-size: 25px;
    margin-bottom: 30px;
}

@media screen and (max-width:780px) {
    .quotation h2 {
        font-size: 25px;
    }
    .quotation h3 {
        font-size: 17px;
        margin-bottom: 15px;
    }
    .quotation {
        padding: 25px 10px;
        margin-top: 0px;
    }
    .quotation a {
        margin: 0px 0px;
        padding: 10px 25px;
    }
}

/* @media screen and (max-width:768px) and (min-width:481px) {
    .quotation h2 {
        font-size: 25px;
    }
    .quotation h3 {
        font-size: 17px;
        margin-bottom: 15px;
    }
    .quotation a {
        margin: 0px 0px;
        padding: 10px 25px;
    }
    .quotation {
        padding: 25px 10px;
        margin-top: 0px;
    }
} */