.servicesCompany1 {
  background-image: url("../../assets/images/page-1_img01.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.servicesCompany2 {
  background-image: url("../../assets/images/page-2_img02.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.servicesCompany3 {
  background-image: url("../../assets/images/services/courier_trips.avif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.servicesCompany4 {
  background-image: url("../../assets/images/page-2_img04.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.servicesCompany .content {
  padding: 140px 10px;
}

@media screen and (max-width: 780px) {
  .servicesCompany .d-flex {
    display: block !important;
  }
  .w-50 {
    width: 100% !important;
  }
  .servicesCompany .content {
    padding: 55px 10px;
  }
  .servicesCompany .bg-dull {
    height: 200px;
  }
}
