.btn-custom {
  border: 1px solid #F15A2B;
  background: #F15A2B;
  color: white;
  text-decoration: none;
  transition: 0.3s ease-out;
  display: inline-block;
}

.btn-custom:hover {
  border: 1px solid #F15A2B;
  color: #F15A2B;
  background: transparent;
  transition: 0.3s ease-out;
}

.bg-none {
  background-color: transparent !important;
}

.bg-lightgrey {
  background-color: lightgrey;
}

.m-c-form {
  margin-right: 80px !important;
  margin-left: 80px !important;
}

@media only screen and (max-width: 1199px) {
  .m-c-form {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }
}
@media screen and (max-width: 375px) {
  .mob-font-size {
    font-size: 35px !important;
  }
}

@media screen and (max-width: 567px) {
  .mob-font-size {
    font-size: 38px;
  }
}
