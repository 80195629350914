.Unique{
    background-image: url('../../assets/images/page-2_img05.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    color: white;
    padding: 150px 0px;
}
.Unique h2{
    font-size: 55px;
}
.Unique h3{
    font-size: 40px;
}

@media screen and (max-width:480px) {
    .Unique{
        padding: 80px 0px;
        text-align: center !important;
    }
    .Unique h2{
        font-size: 25px;
    }
    .Unique h3{
        font-size: 20px;
    }
}
@media screen and (max-width:768px) and (min-width:481px) {
    .Unique{
        padding: 100px 0px;
        text-align: center !important;
    }
    .Unique h2{
        font-size: 35px;
    }
    .Unique h3{
        font-size: 25px;
    }
}
@media screen and (max-width:992px) and (min-width:769px) {
    .Unique{
        padding: 100px 0px;
        text-align: center !important;
    }
    .Unique h2{
        font-size: 40px;
    }
    .Unique h3{
        font-size: 30px;
    }
}