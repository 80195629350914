.navbar-top-contact {
  padding: 10px 0px;
  text-align: end;
  /* color: white; */
  transition: all 0.3s ease-in-out;
  visibility: visible;
}
.fixed {
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
}
.offCanvas {
  display: none;
}
.hidden {
  height: 0px;
  transition: all 0.3s ease-in-out;
}
.navbar-top-contact .phone-icons {
  color: rgb(0, 0, 0) !important;
}
.phone-link {
  color: #f15a2b !important;
}
.phone-link:hover {
  color: #000 !important;
}
.navbar-top-contact a {
  text-decoration: none;
  color: black;
  font-size: 20px;
  padding: 0px 20px;
}
.navbar {
  padding: 10px;
  box-shadow: 0 0 4px 0px #b5b5b5;
  background-color: white !important;
  position: sticky;
  top: 0px;
}
.navbar ul li {
  padding: 0px;
}
.text-orange {
  font-size: 44px !important;
  color: #f15a2b I !important;
}
.navbar a {
  font-size: 20px;
  font-weight: 700;
  color: #f15a2b;
}
.navbar .ms-3 {
  border-left: 2px solid grey;
}
.navbar a:hover,
.navbar a:active,
.navbar a:focus {
  color: #f9957a;
}

@media screen and (max-width: 780px) {
  .fixed {
    display: none;
  }
  .offCanvas {
    display: block;
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    box-shadow: 0 0 13px -1px rgb(0 0 0 / 17%);
    background: #f15a2b;
    z-index: 9;
  }
  .offcanvas-header {
    display: block;
    padding: 50px 0px;
  }
  .offcanvas-header .text-reset {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 25px;
  }
  .offCanvasnav {
    font-size: 35px;
    padding: 3px 0px;
    color: white;
  }
  #offcanvasExample {
    background-color: #f15a2b;
    color: white;
    width: 80%;
  }
  #offcanvasExampleContact {
    height: 60px;
    background-color: #f15a2b;
    color: white;
    top: 0px;
  }
  #offcanvasExampleContact .text-reset {
    font-size: 35px;
    padding: 3px 10px;
    color: white;
  }
  .offcanvas-end a {
    color: white;
    text-decoration: none;
  }
  .offcanvas.offcanvas-start {
    top: 58px;
  }
  .offCanvas .nav-link {
    padding: 5px 10px;
  }
  .ms-3 {
    margin-left: 0rem !important;
  }
  .nav-item {
    text-align: left;
  }

  .activeNav {
    color: #fff;
    background: #f9957a;
  }
}
