.main {
  color: white;
}
.item1,
.item2,
.item3 {
  background-position: center;
  padding-top: 190px;
  background-repeat: no-repeat;
  background-size: cover;
  height: 110vh !important;
  min-height: 700px !important;
}

.item1 {
  background-image: url("../../assets/images/page-1_img01.jpg");
}

.item2 {
  background-image: url("../../assets/images/page-1_img03.jpg");
}
.item2 ul li {
  padding: 5px 0px;
}
.item3 {
  background-image: url("../../assets/images/page-1_img02.jpg");
}

.carousel-control-next {
  right: 50px;
}
.carousel-control-prev {
  right: 110px !important;
  left: unset;
}
.carousel-control-next,
.carousel-control-prev {
  top: 640px;
  padding: 14px;
  border: 2px solid white;
  border-radius: 50%;
  height: 50px;
  width: 50px;
}

.main-content h2 {
  font-size: 55px;
}
.main-content h3 {
  font-size: 40px;
}
.main-content a {
  padding: 10px 20px;
  border: 1px solid #f15a2b;
  background: #f15a2b;
  color: white;
  text-decoration: none;
  transition: 0.3s ease-out;
  display: inline-block;
}
.main-content a:hover {
  border: 1px solid white;
  background: transparent;
  transition: 0.3s ease-out;
}
.main-content ul {
  list-style-type: none;
  padding: 0;
}

/* Responsive */
@media screen and (max-width: 600px) {
  .carousel-control-next,
  .carousel-control-prev {
    top: 680px;
  }
  .text-dark-mob {
    color: #000 !important;
  }
}

@media screen and (max-width: 480px) {
  .carousel-control-next,
  .carousel-control-prev {
    top: 660px;
  }
  .item1,
  .item2,
  .item3 {
    padding-top: 80px;
    min-height: 630px !important;
  }
  .main-content {
    text-align: center !important;
  }
  .main-content h2 {
    font-size: 25px;
  }
  .main-content h3 {
    font-size: 20px;
  }

  .mainHome ul li {
    padding: 10px 0px;
  }
}

@media screen and (max-width: 768px) and (min-width: 481px) {
  /* .main{
        margin-top:-100px ;
    } */
  .main-content h2 {
    font-size: 30px;
  }
  .w-75 {
    width: 100% !important;
  }
  .main-content h3 {
    font-size: 20px;
  }
  .item1,
  .item2,
  .item3 {
    min-height: 650px !important;
    background-position: center;
    padding-top: 100px;
  }
}

@media screen and (max-width: 990px) and (min-width: 769px) {
  .main {
    margin-top: 0px;
  }
}
